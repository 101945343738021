
export default {
  name: `MyCheckSedexCheckResultTestResult`,
  props: {
    myCheck: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    checksDocs() {
      return this.myCheck?.result_documents
    }
  }
}
